<template>
  <div class="aboutUs">
    <div class="banner"></div>
    <div class="comeToUs">
      <div class="content">
        <div
          class="article wow bounceInLeft"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <div class="title">
            <span>走进我们</span>
            <div class="line"></div>
          </div>
          <div class="text">
            <p>
              某某电机股份有限公司始建于1958年，2002年改制为民营股份制企业。60年来，专注电机制造，诚信经营、为用户着想、平衡发展，以“质量好、品种多、规格全”取信于用户，是中国高效电机制造专家。二十多年来，公司生产规模及综合经济效益指数一直保持在全国同行业前列。
            </p>
            <p>
              公司现有员工1800余人，工程技术人员320余名，占地面积41万平方米，年生产能力2000万kW，是中国中小型电机行业最具规模和实力的生产企业之一，系中国机械工业500强、中国电机工业20强。
            </p>
          </div>
        </div>
        <div
          class="photoBox wow bounceInRight"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <img
            src="//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAg28eU7QUo0NHKvgYw2QQ42QI.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="ourAdvantages wow fadeIn" data-wow-duration="1s"
          data-wow-delay="0.5s">
      <div class="content">
        <div class="block">
          <div class="title">
            <h2>我们的优势</h2>
            <div class="line"></div>
          </div>
          <ul class="article">
            <li>
              坚持管理创新，大力实施“精品工程战略”，建立有完善的质量控制和管理体系，先后通过了ISO9001质量管理体系认证、ISO14001环境管理体系认证、OHSAS18001职业健康安全管理体系认证，CQC、CCC认证，
              欧盟CE、美国UL和加拿大CSA认证，中国国家高效认证、低碳认证，美国能源部CC高效等多项认证。
            </li>
            <li>
              公司还被授予“全国质量诚信示范企业”、“全国用户满意服务企业”、“全国守合同重信用企业”、“中国机械工业质量管理奖”等称号。主导产品曾先后获得“国家免检产品“中国名牌产品”、“中国驰名商标”等称号。
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="successCase">
      <div class="content">
        <div class="title">
          <h2>成功案例</h2>
          <div class="line"></div>
        </div>
        <div class="list">
          <ul>
            <li
              v-for="(item, index) in caseList"
              :key="index"
              class="wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div class="photoBox">
                <img :src="item.img" alt="" />
              </div>
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      caseList: [
        {
          img: "https://20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgzMmQ7QUorYv_TzD8AjiEAg.jpg.webp",
          name: "水泥、矿业行业",
        },
        {
          img: "https://20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgycmQ7QUo1_vFpgUw-AI4hAI.jpg.webp",
          name: "机床、减速机行业",
        },
        {
          img: "https://20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgzMmQ7QUorYv_TzD8AjiEAg.jpg.webp",
          name: "水泥、矿业行业",
        },
        {
          img: "https://20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgycmQ7QUo1_vFpgUw-AI4hAI.jpg.webp",
          name: "机床、减速机行业",
        },
        {
          img: "//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgzMmQ7QUorYv_TzD8AjiEAg.jpg.webp",
          name: "水泥、矿业行业",
        },
        {
          img: "https://20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgycmQ7QUo1_vFpgUw-AI4hAI.jpg.webp",
          name: "机床、减速机行业",
        },
      ],
    };
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
};
</script>

<style lang="less" scoped>
.aboutUs {
  .banner {
    background: url("//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgn8eU7QUooJ-w2wYwgA84rAI.jpg")
      no-repeat 50% 50%;
    height: 300px;
  }
  .comeToUs {
    .content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      padding: 64px 0 80px 0;
      .article {
        margin-top: 10px;
        width: 506px;
        margin-right: 90px;
        .title {
          font-size: 30px;
          color: rgb(51, 51, 51);
          position: relative;
          .line {
            width: 43px;
            height: 2px;
            background: rgb(30, 80, 174);
            position: absolute;
            top: 55px;
          }
        }
        .text {
          margin-top: 60px;
          p {
            font-size: 14px;
            line-height: 28px;
            color: rgb(102, 102, 102);
          }
        }
      }
    }
  }
  .ourAdvantages {
    height: 585px;
    background: url(//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAg_8mU7QUovIHJxAQwgA84yQQ.jpg)
        no-repeat repeat top,
      transparent;
    background-size: cover;
    .content {
      position: relative;
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      .block {
        position: absolute;
        //background: red;
        left: 660px;
        width: 600px;
        height: 100%;
      }
      .title {
        position: absolute;
        left: 380px;
        top: 100px;
        h2 {
          font-size: 30px;
          color: #fff;
        }
        .line {
          position: relative;
          width: 43px;
          height: 2px;
          background: rgb(103, 128, 175);
          top: 20px;
        }
      }
      .article {
        position: absolute;
        left: 0px;
        top: 325px;
        width: 555px;
        li {
          list-style: disc;
          color: rgb(102, 102, 102);
          font-size: 14px;
          &:nth-of-type(1) {
            margin-bottom: 45px;
          }
        }
      }
    }
  }
  .successCase {
    .content {
      width: 1200px;
      margin: 0 auto;
      padding-top: 90px;
      box-sizing: border-box;
      height: 870px;
      .title {
        text-align: center;
        font-size: 30px;
        color: #333;
        position: relative;
        .line {
          position: absolute;
          width: 51px;
          height: 2px;
          background: rgb(30, 80, 174);
          left: 577px;
          top: 55px;
        }
      }
      .list {
        margin-top: 60px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin-right: 15px;
            .photoBox {
              width: 380px;
              height: 260px;
            }
            p {
              text-align: center;
              margin: 15px 0;
            }
          }
        }
      }
    }
  }
}
</style>